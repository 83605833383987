import * as React from 'react';
import styles from './Hearts.module.scss';
export const Hearts: React.FC = () => {
  const divs = Array(50).fill(<div className={styles.heart}>&#x2764;</div>);

  return (
    <div className={styles.main}>
      <div className={styles.initialHeart}>{divs}</div>
    </div>
  );
};
